import React from 'react';
import { Typography } from '@material-ui/core';
import renderHTML from 'react-render-html';

export default class TermsAndConditionsPage extends React.Component {

  render() {
    const { content } = this.props;

    return (
      <React.Fragment>
        <Typography variant="display1" align="center">
          Terms and Conditions
        </Typography>

        <Typography variant="subheading" className="custom-tab-content">
          {content && renderHTML(content)}
        </Typography>
      </React.Fragment>
    )
  }
}
