import moment from 'moment';

// Formats a given date to a standard format
export function formatDate(date) {
  return moment.parseZone(date).format('D MMM YYYY');
}

// Formats a given date to a standard format
export function formatTime(date) {
  return moment.parseZone(date).format('D MMM YYYY H:mm');
}
