import { combineReducers } from 'redux';
import { reducer as FlashReducer } from 'redux-flash';
import PromotionReducer from './PromotionReducer';
import SubmissionReducer from './SubmissionReducer';
import SupportReducer from './SupportReducer';

const rootReducer = combineReducers({
  flash: FlashReducer,
  promotion: PromotionReducer,
  submission: SubmissionReducer,
  support: SupportReducer
});

export default rootReducer;
