import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { TextField, InputLabel, FormHelperText } from '@material-ui/core';
import MediaQuery from 'react-responsive';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value) {
      this.props.setValue(this.props.value);
    }
    this.changeValue = this.changeValue.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  handleOnBlur(event) {
    const { onBlur } = this.props;
    if (onBlur) onBlur(event);
  }

  render() {
    const { type, label, hint, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className={'input-container text-field' + (type == 'hidden' ? ' hidden' : '')}>
        {/* Render label separately if long or in small devices so doesn't overlap with text input */}
        <MediaQuery maxWidth={767}>
          {(matches) => {
            if (matches || label.length > 30) {
              return (
                <React.Fragment>
                  <InputLabel style={{display: 'block', marginTop: '1em'}}>{label}</InputLabel>
                  {this.renderTextField(false)}
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment>
                  {this.renderTextField(true)}
                </React.Fragment>
              );
            }
          }}
        </MediaQuery>

        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }

  renderTextField(showLabel) {
    const { type, name, label, required, disabled, multiline, inputLabelProps, inputFieldProps, getValue, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <TextField
        error={isError}
        type={type || "text"}
        multiline={multiline}
        rows="4"
        id={name}
        name={name}
        label={showLabel && label}
        required={required}
        onChange={this.changeValue}
        onBlur={this.handleOnBlur}
        value={getValue() || ''}
        disabled={disabled}
        fullWidth
        InputLabelProps={inputLabelProps}
        inputProps={inputFieldProps}
      />
    );
  }
}

TextInput.propTypes = {
  ...propTypes
};

export default withFormsy(TextInput);
