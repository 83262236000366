import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid, Card, CardContent, Typography, Button, FormHelperText } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import Alert from '../Alert';
import LoadingSpinner from '../LoadingSpinner';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
import { createSupportLog } from 'actions/SupportActions';
import renderHTML from 'react-render-html';

export class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);

    this.state = {
      selectedOption: null,
      showMessage: false,
      categoryNote: null,
      captchaResponse: null,
      captchaError: null
    }
  }

  handleOptionChange(option) {
    this.setState({selectedOption: option});
  }

  handleCategoryChange(event) {
    var cat = _.find(this.props.promotion.support_categories, (c) => { return c.name == event.target.value });
    this.setState({showMessage: cat.allow_message, categoryNote: cat.note});
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { promotion, createSupportLog } = this.props;

    if (this.state.captchaResponse) {
      this.setState({captchaError: null, showMessage: false, categoryNote: null});
      createSupportLog(_.merge(data, {promotion_id: promotion.id}), this.state.captchaResponse);
    } else {
      this.setState({captchaError: 'Captcha is required'});
    }
  }

  onCaptchaChange(captchaResponse) {
    this.setState({captchaResponse: captchaResponse, captchaError: null});
  }

  render() {
    const { promotion, createSuccess, isProcessing } = this.props;
    const { selectedOption, showMessage, categoryNote } = this.state;

    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom align="center">
          Contact Us
        </Typography>
        <Typography variant="subheading" paragraph align="center">
          What action would you like to take?
        </Typography>

        <Grid container spacing={16} justify="center" className="gutter-bottom">
          {promotion.trackable &&
            <Grid item md={4} xs={8}>
              <Card raised={selectedOption == 'track'}>
                <CardContent className="center pointer" onClick={() => this.handleOptionChange('track')}>
                  <PageviewIcon color="primary" style={{fontSize: 50}} />
                  <Typography variant="subheading" gutterBottom>
                    Track Claim
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          }
          <Grid item md={4} xs={8}>
            <Card raised={selectedOption == 'contact'}>
              <CardContent className="center pointer" onClick={() => this.handleOptionChange('contact')}>
                <ContactSupportIcon color="primary" style={{fontSize: 50}} />
                <Typography variant="subheading" gutterBottom>
                  Contact Support
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {selectedOption == 'track' &&
          <React.Fragment>
            <Typography variant="subheading" align="center" className="gutter-top" gutterBottom>
              You can get an immediate update as to what state your claim is in by visiting the Track Claim page
            </Typography>
            <Typography variant="headline" align="center">
              <NavLink exact to={{pathname: '/track'}}>Track Claim</NavLink>
            </Typography>
          </React.Fragment>
        }
        {selectedOption == 'contact' &&
          <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
            <LoadingSpinner show={isProcessing} />
            <Typography variant="subheading" align="center">
              {renderHTML(promotion.contact_us_content)}
            </Typography>
            <Grid container spacing={8} justify="center">
              {createSuccess ?
                <Alert type="success" message="Thank you. We have successfully received your enquiry. A member of our team will respond to you as soon as possible" hideClose />
              :
                <Grid item sm={8} xs={11}>
                  {promotion.has_support_categories &&
                    <SelectInput name="category" label="Category" required
                      options={promotion.support_categories.map(cat => ({value: cat.name, label: cat.name}))}
                      onChange={this.handleCategoryChange}
                      validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                    />
                  }

                  {categoryNote &&
                    <Alert type="info" message={categoryNote} hideClose />
                  }

                  <TextInput name="name" label="Name" required
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />

                  <TextInput type="email" name="email" label="Email" required
                    validations={{isEmail: true}}
                    validationErrors={{
                      isEmail: 'not a valid email',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />

                  <TextInput type="number" name="phone_number" label="Phone Number" required
                    validations={{isNumeric: true}}
                    validationErrors={{
                      isNumeric: 'must be a number',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />

                  <TextInput type="number" name="claim_id" label="Claim ID"
                    validations={{isNumeric: true, isLength: 8}}
                    validationErrors={{
                      isNumeric: 'must be a number',
                      isLength: 'must be 8 digits'
                    }}
                  />

                  {(showMessage || !promotion.has_support_categories) &&
                    <TextInput name="message" label="Message" required={showMessage === false} multiline
                      validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                    />
                  }

                  <ReCAPTCHA
                    sitekey={window.CAPTCHA_CLIENT_KEY || ''}
                    onChange={this.onCaptchaChange}
                    className="g-recaptcha"
                  />
                  {this.state.captchaError &&
                    <FormHelperText error>{this.state.captchaError}</FormHelperText>
                  }

                </Grid>
              }
            </Grid>
            {!createSuccess &&
              <div className="button-container">
                <Button type="submit" variant="raised" color="primary">Submit</Button>
              </div>
            }
          </Formsy>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.support
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSupportLog: (data, captchaResponse) => {
      dispatch(createSupportLog(data, captchaResponse));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
