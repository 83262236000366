import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Button } from '@material-ui/core';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import PreparationCheckModal from 'components/PreparationCheckModal';

export class LandingPage extends React.Component {
  render() {
    const { promotion, navigateTo } = this.props;

    return (
      <React.Fragment>
        <Grid container justify='center'>
          <MediaQuery maxWidth={767}>
            <div className='promo-banner' title="Promo Banner" style={{'backgroundImage': 'url("' + promotion.mobile_banner + '")'}}></div>
          </MediaQuery>
          <MediaQuery minWidth={767}>
            <div className='promo-banner' title="Promo Banner" style={{'backgroundImage': 'url("' + promotion.desktop_banner + '")'}}></div>
          </MediaQuery>
          {promotion && promotion.has_preparation_checks ?
            <a className='claim-cta'>
              <PreparationCheckModal promotion={promotion} onSubmit={() => navigateTo('/claim')}
                openComponent={(props) =>
                  <Button variant='raised' size='large' color='primary' {...props}>
                    {promotion.competition ? 'Enter' : 'Claim'}
                  </Button>
                }
              />
            </a>
            :
            <NavLink className='claim-cta' exact to={{pathname: '/claim', search: this.props.location.search}}>
              <Button variant='raised' size='large' color='primary'>{promotion.competition ? 'Enter' : 'Claim'}</Button>
            </NavLink>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default connect(null, mapDispatchToProps)(withRouter(LandingPage));
