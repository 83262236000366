import React from 'react';
import _ from 'lodash';
import { Grid, FormGroup, Typography } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import AddressInput from 'components/inputs/AddressInput';
import { getStates } from './states';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

function compulsoryFields(values) {
  return (
    <React.Fragment>
      <TextInput name="name" label="Full Name" required
        value={values.name}
        validationErrors={requiredValidation}
      />
      <TextInput type='email' name="email" label="Email" required
        value={values.email}
        validations={{isEmail: true}}
        validationErrors={_.merge(requiredValidation, {isEmail: 'not a valid email'})}
      />
    </React.Fragment>
  );
}

export default function renderDeliveryFields(fulfilmentType, values = {}, country, enableAddressAutoComplete, handleBsbBlur, bankName) {
  const type = fulfilmentType.type;

  if (type === 'cheque' || type === 'physical_gift') {
    return (
      <div>
        {compulsoryFields(values)}
        {enableAddressAutoComplete ?
          <AddressInput label='Address' name='full_address' country={country} acceptPoBox={fulfilmentType.accept_po_box} required validationErrors={requiredValidation} />
        :
          renderAddressFields(country, fulfilmentType.accept_po_box)
        }
      </div>
    );
  } else if (type === 'competition_entry') {
    return compulsoryFields(values);
  } else if (type === 'digital_gift') {
    return (
      <div>
        {compulsoryFields(values)}
        {fulfilmentType.delivery_method == 'sms' &&
          <TextInput type="number" name="mobile_number" label="Mobile Number" required
            validations={{isLength: 10, auMobileNumber: country == 'Australia'}}
            validationErrors={_.merge(requiredValidation, {isLength: 'must be 10 digits', auMobileNumber: 'must be a valid mobile number in the format 04XXXXXXXX'})}
            value={values.mobile_number}
          />
        }
      </div>
    )
  } else if (type === 'bank_transfer') {
    return (
      <React.Fragment>
        {compulsoryFields(values)}

        <FormGroup className="bank-transfer-fields">
          <Typography variant="subheading" style={{fontWeight: 'bold', marginTop: '10px'}}>Bank Account</Typography>

          <TextInput name="account_name" label="Account Name" required
            value={values.account_name}
            validations="isWords"
            validationErrors={_.merge(requiredValidation, {isWords: 'must be only letters and spaces'})}
          />

          {country === 'Australia' ?
            <React.Fragment>
              <TextInput type="number" name="bsb" label="BSB" required
                value={values.bsb}
                onBlur={handleBsbBlur}
                hint={bankName}
                validations="isLength:6"
                validationErrors={_.merge(requiredValidation, {isLength: 'must be 6 digits'})}
              />

              <TextInput type="number" name="account_number" label="Account Number" required
                value={values.account_number}
                validations="maxLength:9"
                validationErrors={_.merge(requiredValidation, {maxLength: 'must be less than 10 digits'})}
              />
            </React.Fragment>
          :
            <TextInput type="number" name="account_number" label="Account Number" required
              value={values.account_number}
              validations="minLength:15,maxLength:16"
              validationErrors={_.merge(requiredValidation, {minLength: 'must be 15-16 digits', maxLength: 'must be 15-16 digits'})}
            />
          }
        </FormGroup>
      </React.Fragment>
    );
  }
}

export function renderAddressFields(country, acceptPoBox, data = {}) {
  return (
    <FormGroup className="address-fields">
      <TextInput name="address_line_1" label="Address Line 1" required
        hint={acceptPoBox ? '' : 'Please note that we cannot accept PO Boxes and lockers'}
        validationErrors={requiredValidation}
        value={data.address_line_1}
      />

      <TextInput name="address_line_2" label="Address Line 2"
        value={data.address_line_2}
      />

      <Grid container spacing={24} justify="center">
        <Grid item xs={6}>
          <TextInput name="suburb" label="Suburb" required
            validationErrors={requiredValidation}
            value={data.suburb}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput type="number" name="postcode" label="Postcode" required
            validations="isLength:4"
            validationErrors={_.merge(requiredValidation, {isLength: 'must be 4 digits'})}
            value={data.postcode}
          />
        </Grid>
      </Grid>

      <Grid container spacing={24} justify="center">
        <Grid item xs={6}>
          {country == 'Australia' ?
            <SelectInput name="state" label="State" required
              options={getStates()}
              validationErrors={requiredValidation}
              value={data.state}
            />
          :
            <TextInput name="state" label="City" required
              validationErrors={requiredValidation}
              value={data.state}
            />
          }
        </Grid>

        <Grid item xs={6}>
          <SelectInput name="country" label="Country" required disabled
            options={['Australia', 'New Zealand']}
            value={country}
            validationErrors={requiredValidation}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
}
