import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as flashMiddleware } from 'redux-flash';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import ReactGA from 'react-ga';

export const history = createBrowserHistory({basename: window.BASE_PATH});
history.listen((location, action) => {
  if (window.GA_TRACKING_ID) {
    ReactGA.pageview(window.location.href);
  }
});

const middlewares = [routerMiddleware(history), flashMiddleware({timeout: 10000}), thunkMiddleware];

// Enables the Redux DevTools extension to Chrome & Firefox
// See https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(
    applyMiddleware(
      ...middlewares
    )
  )
);

export default store;
