import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';

// Loading spinner for entire screen for use while processing
export default class LoadingSpinner extends React.Component {
  render() {
    const { show } = this.props;

    if (!show) return null;

    return (
      <div className="loading-overlay">
        <CircularProgress color="inherit" size={50} />
        <br />
        <Typography variant="headline" align="center" color="inherit">
          Loading...
        </Typography>
      </div>
    )
  }
}

LoadingSpinner.defaultProps = {
  show: false
};
