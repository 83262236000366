import React from 'react';
import _ from 'lodash';
import TextInput from 'components/inputs/TextInput';
import AutocompleteInput from 'components/inputs/AutocompleteInput';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export default function renderMerchantField(input, value, key, options) {
  if (input.merchant_field === 'abn') {
    return (
      <TextInput key={key} name={input.id} type='number' label={input.label} value={value || window.MERCHANT_DATA.abn} hint={input.hint} required={input.required}
        validations={{isNumeric: true, isLength: 11}}
        validationErrors={_.merge(requiredValidation, {isNumeric: 'must be a number', isLength: 'must be 11 digits'})}
      />
    )
  } else if (input.merchant_field === 'name') {
    if (input.use_dropdown) {
      return (
        <React.Fragment key={key}>
          <AutocompleteInput name="promotion_merchant_id" label={input.label} required={input.required}
            options={input.merchants} onChange={(o) => options.updateMerchantID(o.value)}
            validationErrors={{isDefaultRequiredValue: "can't be blank"}}
          />
          <TextInput name={input.id} type="hidden" label={input.label} value={options.merchantID} />

          {input.extra_filter &&
            <AutocompleteInput name="merchant_location_id" label={input.extra_filter_label} required={input.required}
              options={_.sortBy(_.filter(input.merchant_locations, {merchant_id: options.merchantID}), ['label'])}
              validationErrors={{isDefaultRequiredValue: "can't be blank"}}
            />
          }
        </React.Fragment>
      )
    } else {
      return (
        <TextInput key={key} name={input.id} label={input.label} value={value || window.MERCHANT_DATA.name} hint={input.hint} required={input.required}
          validationErrors={requiredValidation}
        />
      )
    }
  } else if (input.merchant_field === 'postcode') {
    return (
      <TextInput key={key} name={input.id} type='number' label={input.label} value={value || window.MERCHANT_DATA.potcode} hint={input.hint} required={input.required}
        validations={{isNumeric: true, isLength: 4}}
        validationErrors={_.merge(requiredValidation, {isNumeric: 'must be a number', isLength: 'must be 4 digits'})}
      />
    )
  }
}
