import {
  CREATE_SUPPORT_LOG_REQUEST,
  CREATE_SUPPORT_LOG_SUCCESS,
  CREATE_SUPPORT_LOG_FAILURE
} from 'actions/SupportActions';

const initialState = {
  isProcessing: false,
  createSuccess: false
};

export default function support(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUPPORT_LOG_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true,
        createSuccess: false
      });
    }
    case CREATE_SUPPORT_LOG_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        createSuccess: true
      });
    }
    case '@@router/LOCATION_CHANGE':
    case CREATE_SUPPORT_LOG_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
