import axios from 'axios';

export const FETCH_PROMOTION_REQUEST = 'FETCH_PROMOTION_REQUEST';
export const FETCH_PROMOTION_SUCCESS = 'FETCH_PROMOTION_SUCCESS';
export const FETCH_PROMOTION_FAILURE = 'FETCH_PROMOTION_FAILURE';

function fetchPromotionRequest() {
  return {
    type: FETCH_PROMOTION_REQUEST
  }
}

function fetchPromotionSuccess(promotion) {
  return {
    type: FETCH_PROMOTION_SUCCESS,
    promotion
  }
}

function fetchPromotionFailure() {
  return {
    type: FETCH_PROMOTION_FAILURE
  }
}

export function fetchPromotion(id) {
  return dispatch => {
    dispatch(fetchPromotionRequest());
    return axios.get('/promotions/' + id)
      .then(response => {
        dispatch(fetchPromotionSuccess(response.data.promotion));
      }).catch(error => {
        dispatch(fetchPromotionFailure());
      });
  }
}
