import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Typography, Grid, Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import renderHTML from 'react-render-html';

export class ThanksPage extends React.Component {
  render() {
    const { submission, promotion } = this.props;

    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom align="center">
          Congratulations!
        </Typography>

        <CheckCircle style={{color: green[500], fontSize: 70, margin: '20px auto', display: 'block'}} />

        {submission &&
          <React.Fragment>
            <Typography variant="headline" paragraph align="center">
              Your {promotion.competition ? 'entry' : 'claim'} has been submitted successfully
            </Typography>
            <Grid container spacing={16} justify="center">
              <Typography className="block" variant="body1" paragraph align="center">
                 A confirmation has been sent to you via the email address you provided.&nbsp;
                 {promotion.trackable && 'You can also use the following claim ID to follow the status of your claim.'}
              </Typography>
              <Typography className="block" variant="headline" gutterBottom align="center">
                {promotion.competition ? 'Entry' : 'Claim'} ID: {submission.tracking_id}
              </Typography>
              {promotion.thank_you_page_content &&
                <Typography className="block" variant="body1" paragraph align="center">
                  <div>{renderHTML(promotion.thank_you_page_content)}</div>
                </Typography>
              }
              {promotion.support_email &&
                <Typography className="block" variant="body1" paragraph align="center">
                  If you have any questions, please&nbsp;
                  {promotion.support_phone &&
                    <React.Fragment>
                      contact us on&nbsp;
                      <a href={`tel:${promotion.support_phone}`}>{promotion.support_phone}</a> or&nbsp;
                    </React.Fragment>
                  }
                   email us at&nbsp;
                  <a href={`mailto:${promotion.support_email}`}>{promotion.support_email}</a>.
                </Typography>
              }
              <Typography className="block" variant="body1" paragraph align="center">
                Thank you.
              </Typography>
              {promotion.extra_claim_cta &&
                <NavLink exact to={{pathname: '/claim', search: this.props.location.search}} className="cta-link">
                  <Button variant="raised">Make another claim</Button>
                </NavLink>
              }
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.submission
  };
}

export default withRouter(connect(mapStateToProps)(ThanksPage));
