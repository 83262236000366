import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormHelperText } from '@material-ui/core';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

const format = 'DD/MM/YYYY';

export class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: (this.props.value && moment(this.props.value, format)) || null
    };

    this.props.setValue(this.props.value, false);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.format(format));
    this.setState({ value: event });
  }

  render() {
    const { name, label, required, hint, getValue, getErrorMessage, isPristine, startDate, endDate, disableYearSelection } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            error={isError}
            id={name}
            name={name}
            label={label}
            required={required}
            value={this.state.value}
            onChange={this.changeValue}
            format={format}
            openToYearSelection={!disableYearSelection}
            fullWidth
            minDateMessage="" // Handle error ourselves
            maxDateMessage="" // Handle error ourselves
            minDate={startDate || '1900-01-01'}
            maxDate={endDate || '2100-01-01' }
          />
        </MuiPickersUtilsProvider>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

DateInput.propTypes = {
  ...propTypes
};

export default withFormsy(DateInput);
