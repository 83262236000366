import axios from 'axios';
import { flashMessage } from 'redux-flash';

export const CREATE_SUPPORT_LOG_REQUEST = 'CREATE_SUPPORT_LOG_REQUEST';
export const CREATE_SUPPORT_LOG_SUCCESS = 'CREATE_SUPPORT_LOG_SUCCESS';
export const CREATE_SUPPORT_LOG_FAILURE = 'CREATE_SUPPORT_LOG_FAILURE';

function createSupportLogRequest() {
  return {
    type: CREATE_SUPPORT_LOG_REQUEST
  }
}

function createSupportLogSuccess() {
  return {
    type: CREATE_SUPPORT_LOG_SUCCESS
  }
}

function createSupportLogFailure() {
  return {
    type: CREATE_SUPPORT_LOG_FAILURE
  }
}

export function createSupportLog(data, captchaResponse) {
  return dispatch => {
    dispatch(createSupportLogRequest());
    return axios.post('/support_logs', {support_log: data, captcha_response: captchaResponse})
      .then(response => {
        dispatch(createSupportLogSuccess());
      }).catch(error => {
        dispatch(createSupportLogFailure());
        dispatch(flashMessage('Your request was unsuccessful. Please try again later', {isError: true}));
      });
  }
}
