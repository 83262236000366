import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Typography, Grid, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import renderDeliveryFields from 'utils/deliveryRenderer';
import { fetchFailedSubmission, updateFailedSubmission, fetchBankName } from 'actions/SubmissionActions';

export class UpdateClaimPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { promotion, location } = this.props;

    const params = queryString.parse(location.search);
    this.props.fetchFailedSubmission(promotion.id, params.token);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { promotion, location, updateFailedSubmission } = this.props;

    const params = queryString.parse(location.search);
    updateFailedSubmission(promotion.id, params.token, data);
  }

  render() {
    const { submission, promotion, isProcessing, fetchBankName, bankName } = this.props;

    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom align="center">
          Update Claim Details
        </Typography>

        {submission &&
          <React.Fragment>
            <Typography variant="subheading" align="center" gutterBottom>
              Please update your details below so your claim can be reprocessed.
            </Typography>
            <Typography variant="subheading" align="center" className="bold">Claim ID: {submission.tracking_id}</Typography>
            <Typography variant="subheading" align="center" className="bold" gutterBottom>Failure Reason: {submission.fail_reason}</Typography>
            <Formsy onValidSubmit={this.handleSubmit}>
              <Grid container spacing={16} justify="center">
                <Grid item xs={8}>
                  {renderDeliveryFields(submission.fulfilment_type, submission, promotion.country, null, (e) => fetchBankName(e.target.value), bankName)}
                </Grid>
              </Grid>
              <div className="button-container">
                <Button type="submit" variant="raised" color="primary" disabled={isProcessing}>Submit</Button>
              </div>
            </Formsy>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.submission
  };
}

const mapDispatchToProps = {
  fetchFailedSubmission: (promotionId, token) => fetchFailedSubmission(promotionId, token),
  updateFailedSubmission: (promotionId, token, data) => updateFailedSubmission(promotionId, token, data),
  fetchBankName: (bsb) => fetchBankName(bsb)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateClaimPage));
