import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Button } from '@material-ui/core';
import _ from 'lodash';
import FulfilmentTypeOption from './FulfilmentTypeOption';

export default class FulfilmentForm extends React.Component {
  render() {
    const { product, fulfilmentTypes, formData, handleNext, handleBack } = this.props;
    const options = _.filter(fulfilmentTypes, function(t) { return _.includes(product.fulfilment_types, t.id) });

    return (
      <React.Fragment>
        {options.map((option, i) =>
          <FulfilmentTypeOption
            key={i}
            option={option}
            formData={formData}
            handleNext={handleNext}
            optionSelected={formData.fulfilment_type_id == option.id}
          />
        )}
        <div className="button-container">
          <Button onClick={handleBack}>Back</Button>
        </div>
      </React.Fragment>
    )
  }
}
