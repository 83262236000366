export function getStates() {
  return [
    {label: 'New South Wales', value: 'NSW'},
    {label: 'Victoria', value: 'VIC'},
    {label: 'Queensland', value: 'QLD'},
    {label: 'Western Australia', value: 'WA'},
    {label: 'South Australia', value: 'SA'},
    {label: 'Tasmania', value: 'TAS'},
    {label: 'Australian Capital Territory', value: 'ACT'},
    {label: 'Northern Territory', value: 'NT'}
  ]
}
