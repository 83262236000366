import React from 'react';
import _ from 'lodash';
import { propTypes, withFormsy } from 'formsy-react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core';

class CheckboxGroupInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value) {
      this.props.setValue(this.props.value);
    }
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const {value, checked} = event.target;
    const values = this.props.getValue() || [];
    const newValues = checked ? _.concat(values, value) : _.pull(values, value);
    this.props.setValue(newValues);
  }

  render() {
    const { getValue, label, options, required, hint, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const error = !isPristine() && errorMessage != null;

    return (
      <React.Fragment>
        <FormControl component="fieldset" error={error}>
          <FormLabel required={required}>
            {label}
          </FormLabel>
          <FormGroup>
            {options.map((option, i) => (
              <FormControlLabel
                key={i}
                control={<Checkbox value={option} onChange={this.changeValue} checked={_.includes(getValue(), option)}/>}
                label={option}
              />
            ))}
          </FormGroup>
          {error &&
            <FormHelperText error>{errorMessage}</FormHelperText>
          }
          {hint &&
            <FormHelperText>{hint}</FormHelperText>
          }
        </FormControl>
      </React.Fragment>
    );
  }
}

CheckboxGroupInput.propTypes = {
  ...propTypes
};

export default withFormsy(CheckboxGroupInput);
