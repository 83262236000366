import React from 'react';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import CheckboxGroupInput from 'components/inputs/CheckboxGroupInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import RadioInput from 'components/inputs/RadioInput';
import SelectInput from 'components/inputs/SelectInput';
import AutocompleteInput from 'components/inputs/AutocompleteInput';
import TextInput from 'components/inputs/TextInput';
import DateInput from 'components/inputs/DateInput';
import SlideInput from 'components/inputs/SlideInput';
import FileInput from 'components/inputs/FileInput';
import renderHTML from 'react-render-html';
import InputExampleModal from 'components/InputExampleModal';
import renderMerchantField from './merchantFields';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export default function renderInput(input, value, key, filenames, options) {
  if (input.type === 'text') {
    return (
      <TextInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        validationErrors={requiredValidation}
      />
    )
  } else if (input.type === 'date') {
    return (
      <DateInput key={key} type="date" name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        startDate={input.start_date} endDate={input.end_date} disableYearSelection={input.disable_year_selection}
        validations={{
          dateSameOrAfter: input.start_date,
          dateSameOrBefore: input.end_date
        }}
        validationErrors={_.merge(requiredValidation, {
          dateSameOrAfter: 'Date selected outside eligible date range',
          dateSameOrBefore: 'Date selected outside eligible date range'
        })}
      />
    )
  } else if (input.type === 'number') {
    return (
      <TextInput key={key} type='number' name={input.id} label={input.label} min={input.min} max={input.max} value={value} hint={input.hint} required={input.required}
        validations={{
          isNumeric: true,
          numberEqualOrGreater: input.min,
          numberEqualOrLesser: input.max
        }}
        validationErrors={_.merge(requiredValidation, {
          isNumeric: 'must be a number',
          numberEqualOrGreater: `Number must be ${input.min} or higher`,
          numberEqualOrLesser: `Number must be ${input.max} or lower`,
        })}
        inputFieldProps={{
          step: 'any'
        }}
      />
    )
  } else if (input.type === 'email') {
    return (
      <TextInput key={key} type='email' name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        validations={{isEmail: true}}
        validationErrors={_.merge(requiredValidation, {isEmail: 'not a valid email'})}
      />
    )
  } else if (input.type === 'regex') {
    return (
      <TextInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        validations={{matchRegexp: new RegExp(input.regex)}}
        validationErrors={_.merge(requiredValidation, {matchRegexp: 'not a valid format'})}
      />
    )
  } else if (input.type === 'checkbox') {
    return (
      <CheckboxInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} />
    )
  } else if (input.type === 'select') {
    return (
      <SelectInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        options={input.options}
        validationErrors={requiredValidation}
      />
    )
  } else if (input.type === 'autocomplete') {
    return (
      <AutocompleteInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        options={input.options.map(opt => ({value: opt, label: opt}))}
        validationErrors={requiredValidation}
      />
    )
  } else if (input.type === 'radio') {
    return (
      <RadioInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
        options={input.options}
        validationErrors={requiredValidation}
      />
    )
  } else if (input.type === 'slide') {
    return (
      <SlideInput key={key} name={input.id} label={input.label} min={input.min} max={input.max} value={value} hint={input.hint} required={input.required}
        validationErrors={requiredValidation}
      />
    )
  } else if (input.type === 'checkboxgroup') {
    return (
      <CheckboxGroupInput key={key} name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
                          options={input.options}
                          validations={{minLength: input.required ? 1 : 0}}
                          validationErrors={{minLength: 'at least one must be selected',
                                             isDefaultRequiredValue: 'at least one must be selected'}}
      />
    )
  } else if (input.type === 'file') {
    return (
      <React.Fragment key={key}>
        <FileInput type='file' name={input.id} label={input.label} value={value} hint={input.hint} required={input.required}
          filename={filenames[input.id]}
          onFileChange={options.onFileChange}
          validationErrors={requiredValidation}
        />
        {(input.example_image || input.example_text) &&
          <InputExampleModal buttonLabel="View Example" image={input.example_image} text={input.example_text} />
        }
      </React.Fragment>
    )
  } else if (input.type === 'content') {
    return <Typography key={key} variant='subheading' className="form-input-content">{renderHTML(input.content)}</Typography>
  } else if (input.type === 'merchant') {
    return (
      renderMerchantField(input, value, key, options)
    )
  }
}
