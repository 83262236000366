/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "babel-polyfill";

import { bugsnagClient } from 'utils/bugsnag';

import React from 'react';
import ReactDOM from 'react-dom';
import createPlugin from 'bugsnag-react';
import App from 'components/App';
import axios from 'axios';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import 'typeface-roboto';
import 'utils/formsyValidations';

function getToken() {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta && meta.getAttribute('content');
}

axios.defaults.baseURL = '/api/v1';
axios.defaults.headers.common['X-CSRF-Token'] = getToken();

if (window.GA_TRACKING_ID) {
  ReactGA.initialize(window.GA_TRACKING_ID);
  ReactGA.pageview(window.location.href);
}

if (window.FB_PIXEL_ID) {
  ReactPixel.init(window.FB_PIXEL_ID);
}

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
ReactDOM.render(
  <ErrorBoundary>
    <App/>
  </ErrorBoundary>,
  document.getElementById('root')
);
