import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import MediaQuery from 'react-responsive';

export default class ResponsiveStepper extends React.Component {
  render() {
    const { steps, activeStep } = this.props;

    return <React.Fragment>
           <MediaQuery maxWidth={767}>
            <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          </MediaQuery>
          <MediaQuery minWidth={767}>
            <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          </MediaQuery>
        </React.Fragment>
  }
}
