import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Typography, Grid, Button, Stepper, Step, StepLabel, StepContent, FormHelperText } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { formatDate } from 'utils/dates';
import humanizeString from 'humanize-string';
import LoadingSpinner from '../LoadingSpinner';
import { fetchSubmission } from 'actions/SubmissionActions';
import ReCAPTCHA from 'react-google-recaptcha';

export class TrackSubmissionPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
    this.recaptchaRef = React.createRef();
    this.onCaptchaChange = this.onCaptchaChange.bind(this);

    this.state = {
      captchaResponse: null,
      captchaError: null
    }
  }

  componentDidUpdate() {
    const { fetchError } = this.props;

    if (fetchError) {
      this.formRef.current.updateInputsWithError({tracking_id: fetchError});
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    if (this.state.captchaResponse) {
      this.setState({captchaError: null});
      this.props.fetchSubmission(this.props.promotion_id, data.tracking_id, this.state.captchaResponse);
      // Reset recaptcha
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
        this.onCaptchaChange(null);
      }
    } else {
      this.setState({captchaError: 'Captcha is required'});
    }
  }

  onCaptchaChange(captchaResponse) {
    this.setState({captchaResponse});
  }

  render() {
    const { submission, isFetching } = this.props;

    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom align="center">
          Track Claim
        </Typography>

        <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
          <LoadingSpinner show={isFetching} />
          <Grid container spacing={8} justify="center">
            <Typography variant="body1" paragraph align="center">
              Enter your claim ID below to see the status of your claim.
            </Typography>
            <Grid item sm={8} xs={11}>
              <TextInput name="tracking_id" label="Claim ID" required
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />

              <ReCAPTCHA
                sitekey={window.CAPTCHA_CLIENT_KEY || ''}
                onChange={this.onCaptchaChange}
                className="g-recaptcha"
                ref={this.recaptchaRef}
              />
              {this.state.captchaError &&
                <FormHelperText error>{this.state.captchaError}</FormHelperText>
              }

            </Grid>
          </Grid>
          <div className="button-container">
            <Button type="submit" variant="raised" color="primary">Submit</Button>
          </div>
        </Formsy>

        {submission && submission.history &&
          <React.Fragment>
            <Typography variant="display1" paragraph align="center" style={{marginTop: '1em'}}>
              Claim #{submission.tracking_id}
            </Typography>
            <Grid container spacing={16} justify="center">
              <Stepper activeStep={submission.history.length} orientation="vertical">
                <Step>
                  <StepLabel>
                    <Typography variant="headline">
                      {formatDate(submission.created_at)} - Claim submitted
                    </Typography>
                  </StepLabel>
                </Step>
                {submission.history.map((statusChange, index) => (
                  <Step key={index + 1}>
                    <StepLabel completed error={statusChange.status === 'declined' || statusChange.status === 'fulfilment_failed'}>
                      <Typography variant="headline">
                        {formatDate(statusChange.date)} - {humanizeString(statusChange.status)}
                      </Typography>
                    </StepLabel>
                    {(statusChange.status === 'declined' || statusChange.status === 'fulfilment_failed') &&
                      <StepContent>
                        <Typography variant="headline">Reason: {statusChange.description}</Typography>
                      </StepContent>
                    }
                    {statusChange.status === 'fulfilled' && submission.tracking_url != null && submission.tracking_number != null &&
                      <StepContent>
                        <Typography variant="headline">
                          Tracking URL: <a href={submission.tracking_url} target="_blank">{submission.tracking_url}</a>
                        </Typography>
                        <Typography variant="headline">Tracking Number: {submission.tracking_number}</Typography>
                      </StepContent>
                    }
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.submission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSubmission: (promotionId, trackingId, captchaResponse) => {
      dispatch(fetchSubmission(promotionId, trackingId, captchaResponse));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackSubmissionPage);
