import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import DetailsForm from './DetailsForm';
import FulfilmentForm from './FulfilmentForm';
import DeliveryForm from './DeliveryForm';
import LoadingSpinner from './LoadingSpinner';
import renderHTML from 'react-render-html';
import _ from 'lodash';
import { submitSubmission, fetchBankName } from 'actions/SubmissionActions';
import ResponsiveStepper from './ResponsiveStepper';

export class SubmissionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      formData: {
        fields: {},
        delivery: {}
      },
      filenames: {}
    };

    this.renderStep = this.renderStep.bind(this);
    this.updateProductId = this.updateProductId.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDetailsSubmit = this.handleDetailsSubmit.bind(this);
    this.handleFulfilmentSubmit = this.handleFulfilmentSubmit.bind(this);
    this.handleDeliverySubmit = this.handleDeliverySubmit.bind(this);
    this.handleBsbBlur = this.handleBsbBlur.bind(this);

    // If every product in the promotion has only one fulfilment type, make it two steps
    if (_.reduce(this.props.promotion.products, (lengthOne, product) => { return lengthOne && (product.fulfilment_types.length == 1) }, true)) {
      this.steps = ['Details', 'Delivery'];
    } else {
      this.steps = ['Details', 'Fulfilment', 'Delivery'];
    }
  }

  renderStep(step) {
    const { promotion } = this.props;
    switch (step) {
      case 'Details':
        return (
          <DetailsForm promotion={promotion}
                       handleNext={this.handleDetailsSubmit}
                       formData={this.state.formData}
                       filenames={this.state.filenames}
                       onFileChange={this.onFileChange}
                       updateProductId={this.updateProductId}
          />
        );
      case 'Fulfilment':
        const product = _.find(promotion.products, {value: this.state.formData.product_id});
        return (
          <FulfilmentForm handleNext={this.handleFulfilmentSubmit}
                          handleBack={this.handleBack}
                          product={product}
                          fulfilmentTypes={promotion.fulfilment_types}
                          formData={this.state.formData}
          />
        );
      case 'Delivery':
        return (
          <DeliveryForm handleBack={this.handleBack}
                        handleNext={this.handleDeliverySubmit}
                        promotion={promotion}
                        formData={this.state.formData}
                        handleBsbBlur={this.handleBsbBlur}
                        bankName={this.props.bankName}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  updateProductId(product_id) {
    this.setState({ formData: _.merge(this.state.formData, {product_id: product_id}) });
  }

  onFileChange(fieldId, filename) {
    this.setState({ filenames: _.merge(this.state.filenames, {[fieldId]: filename}) });
  }

  handleNext() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  }

  handleBsbBlur(event) {
    this.props.fetchBankName(event.target.value);
  }

  handleDetailsSubmit(data) {
    const productId = data.product_id || this.state.formData.product_id;
    // Set IDs for standard submission attributes set up in fields
    this.setState({ formData: _.merge(this.state.formData, {fields: data, product_id: productId, promotion_merchant_id: data.promotion_merchant_id, merchant_location_id: data.merchant_location_id}) });

    const productFulfilmentTypes = this.props.promotion.products.find(function(product) { return product.value == productId }).fulfilment_types;
    if (productFulfilmentTypes.length == 1) {
      this.handleFulfilmentSubmit(productFulfilmentTypes[0]);
      return;
    }

    this.handleNext();
  }

  handleFulfilmentSubmit(fulfilment_type_id) {
    this.setState({ formData: _.merge(this.state.formData, {fulfilment_type_id: fulfilment_type_id}) });
    this.handleNext();
  }

  handleDeliverySubmit(data, _resetForm, _invalidateForm) {
    const { formData } = this.state;
    this.setState({ formData: _.merge(formData, {delivery: data}) });

    this.props.submitSubmission(this.props.promotion.id, _.clone(formData, true) );
  }

  render() {
    const { promotion, isProcessing } = this.props;

    return (
      <React.Fragment>
        {promotion.open || window.PROMOTION_OPEN_OVERRIDE ?
          <React.Fragment>
            { promotion.form_header_image &&
              <div align="center"><img src={promotion.form_header_image} className='header-image' alt="Header image" /></div>
            }
            <Typography variant="display1" align="center">
              {promotion.name}
            </Typography>
            <ResponsiveStepper activeStep={this.state.activeStep} steps={this.steps}/>
            {this.renderStep( this.steps[this.state.activeStep] )}
            <LoadingSpinner show={isProcessing} />
          </React.Fragment>
        :
          <Typography variant="subheading" align="center">
            <WarningIcon />
            {promotion.close_page_content ?
              <div>{renderHTML(promotion.close_page_content)}</div>
            :
              <div>This promotion is not currently open</div>
            }
          </Typography>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.submission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitSubmission: (promotionId, data) => {
      dispatch(submitSubmission(promotionId, data));
    },
    fetchBankName: (bsb) => {
      dispatch(fetchBankName(bsb));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionForm);
