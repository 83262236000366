import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export default class ThanksPage extends React.Component {
  render() {
    const { promotion } = this.props;

    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom align="center">
          Thanks!
        </Typography>

        <Typography variant="headline" paragraph align="center">
          Your {promotion.competition ? 'entry' : 'claim'} details have been updated successfully
        </Typography>

        <Grid container spacing={16} justify="center">
          <Typography className="block" variant="body1" paragraph align="center">
             A notification will be sent to you via the email address you provided once fulfilment has been retried with these updated details.
          </Typography>
          {promotion.support_email &&
            <Typography className="block" variant="body1" paragraph align="center">
              If you have any questions, please&nbsp;
              {promotion.support_phone &&
                <React.Fragment>
                  contact us on&nbsp;
                  <a href={`tel:${promotion.support_phone}`}>{promotion.support_phone}</a> or&nbsp;
                </React.Fragment>
              }
               email us at&nbsp;
              <a href={`mailto:${promotion.support_email}`}>{promotion.support_email}</a>.
            </Typography>
          }
          <Typography className="block" variant="body1" paragraph align="center">
            Thank you.
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }
}
