import {
  FETCH_SUBMISSION_REQUEST,
  FETCH_SUBMISSION_SUCCESS,
  FETCH_SUBMISSION_FAILURE,
  SUBMIT_SUBMISSION_REQUEST,
  SUBMIT_SUBMISSION_SUCCESS,
  SUBMIT_SUBMISSION_FAILURE,
  FETCH_FAILED_SUBMISSION_REQUEST,
  FETCH_FAILED_SUBMISSION_SUCCESS,
  FETCH_FAILED_SUBMISSION_FAILURE,
  UPDATE_FAILED_SUBMISSION_REQUEST,
  UPDATE_FAILED_SUBMISSION_SUCCESS,
  UPDATE_FAILED_SUBMISSION_FAILURE,
  FETCH_BANK_NAME_SUCCESS
} from 'actions/SubmissionActions';

const initialState = {
  isFetching: false,
  isProcessing: false,
  submission: null,
  fetchError: null,
  bankName: null
};

export default function submission(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBMISSION_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        submission: null,
        fetchError: null
      });
    }
    case FETCH_SUBMISSION_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        submission: action.submission,
        fetchError: null
      });
    }
    case FETCH_SUBMISSION_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        submission: null,
        fetchError: action.error
      });
    }
    case SUBMIT_SUBMISSION_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true,
        submission: null
      });
    }
    case SUBMIT_SUBMISSION_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        submission: action.submission
      });
    }
    case SUBMIT_SUBMISSION_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false,
        submission: null
      });
    }
    case FETCH_FAILED_SUBMISSION_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        submission: null
      });
    }
    case FETCH_FAILED_SUBMISSION_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        submission: action.submission
      });
    }
    case FETCH_FAILED_SUBMISSION_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        submission: null
      });
    }
    case UPDATE_FAILED_SUBMISSION_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case UPDATE_FAILED_SUBMISSION_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case UPDATE_FAILED_SUBMISSION_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case FETCH_BANK_NAME_SUCCESS: {
      return Object.assign({}, state, {
        bankName: action.bank_name
      });
    }
    default:
      return state;
  }
}
