import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { InputLabel, TextField, FormHelperText, Button, CircularProgress } from '@material-ui/core';
import ActiveStorageProvider from 'react-activestorage-provider';

export class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileError: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(response) {
    const { name, onFileChange, setValue } = this.props;

    if (response.success) {
      onFileChange(name, response.filename);
      this.setState({fileError: null});
      setValue(response.key);
    } else {
      this.setState({fileError: response.error});
      setValue('');
    }
  }

  render() {
    const { type, name, label, required, filename, inputLabelProps, hint, getErrorMessage, isPristine } = this.props;
    const errorMessage = this.state.fileError || getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <ActiveStorageProvider
          endpoint={{
            path: '/api/v1/file_uploads',
            model: 'FileUpload',
            attribute: 'signed_id',
            method: 'POST'
          }}
          onSubmit={this.handleSubmit}
          render={({ handleUpload, uploads, ready }) => (
            <div>
              <InputLabel htmlFor={name} className="file-label" error={isError} required={required}>{label}</InputLabel>
              <TextField
                type={type}
                disabled={!ready}
                id={name}
                name={name}
                onChange={e => handleUpload(e.currentTarget.files)}
                fullWidth
                style={{display: 'none'}}
              />

              <label htmlFor={name}>
                <Button variant="raised" component="span">
                  <AttachmentIcon className="attachment-icon" />
                  {filename || 'Upload'}

                  {uploads[0] && _.includes(['waiting', 'uploading'], uploads[0].state) &&
                    <CircularProgress size={20} className="upload-progress" />
                  }
                </Button>
              </label>

              {!isPristine() && errorMessage &&
                <FormHelperText error={isError}>{errorMessage}</FormHelperText>
              }
              {!isPristine() && uploads[0] && uploads[0].state == 'error' &&
                <FormHelperText error={isError}>{uploads[0].error}</FormHelperText>
              }
              {hint &&
                <FormHelperText>{hint}</FormHelperText>
              }
            </div>
          )}
        />
      </div>
    );
  }
}

FileInput.propTypes = {
  ...propTypes
};

export default withFormsy(FileInput);
