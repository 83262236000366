import React from 'react';
import { Modal, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '10%',
    width: '80%',
    [theme.breakpoints.up('md')]: {
      left: '30%',
      width: '40%'
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    textAlign: 'center',
    maxHeight: '80%',
    overflow: 'scroll'
  },
  image: {
    maxWidth: '75%'
  },
  text: {
    marginTop: '1em',
    marginBottom: '1em'
  }
});

export class InputExampleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tab: 0
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {
    const { buttonLabel, image, text, classes } = this.props;
    const { open, tab } = this.state;

    return (
      <React.Fragment>
        <Button onClick={this.handleOpen} className='select-artwork'>{buttonLabel}</Button>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            {image &&
              <img src={image} className={classes.image} alt="Example illustration image" />
            }
            {text &&
              <Typography className={classes.text}>{text}</Typography>
            }
            <div style={{marginTop: '10px'}}>
              <Button variant="contained" onClick={this.handleClose}>Ok</Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InputExampleModal);
