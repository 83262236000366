import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import ScrollToTop from 'components/ScrollToTop';
import store, { history } from 'utils/store';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import PromoPage from 'components/pages/PromoPage';

class App extends React.Component {
  getMuiTheme() {
    return createMuiTheme({
      palette: {
        primary: {main: window.THEME_PRIMARY_COLOUR ? '#' + window.THEME_PRIMARY_COLOUR : indigo[500]}
      },
      typography: {
        fontFamily: window.CUSTOM_FONT
      }
    })
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <div>
              <ScrollToTop>
                <PromoPage />
              </ScrollToTop>
            </div>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App;
