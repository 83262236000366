import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import { InputLabel, Radio, RadioGroup, FormHelperText, FormControlLabel } from '@material-ui/core';

export class RadioInput extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.target.value);
  }

  render() {
    const { name, label, required, options, hint, getValue, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <InputLabel htmlFor={name} error={isError} required={required}>{label}</InputLabel>
        <RadioGroup
          id={name}
          name={name}
          onChange={this.changeValue}
          value={getValue() || ""}
        >
          {options.map((option, i) => (
            _.isString(option) ?
              <FormControlLabel key={i} value={option} control={<Radio />} label={option} />
            :
              <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText >{hint}</FormHelperText>
        }
      </div>
    );
  }
}

RadioInput.propTypes = {
  ...propTypes
};

export default withFormsy(RadioInput);
