import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import CheckboxInput from 'components/inputs/CheckboxInput';
import TextInput from 'components/inputs/TextInput';
import renderDeliveryFields from 'utils/deliveryRenderer';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHelperText } from '@material-ui/core';
import { withRouter, NavLink } from 'react-router-dom';

export class DeliveryForm extends React.Component {
  // TODO: Save state on back for delivery fields and pass in to inputs

  constructor(props){
    super(props);

    this.state = {
      captchaError: null,
      captchaResponse: null,
      openDialog: false,
      confirmed: false
    };

    this.recaptchaRef = React.createRef();
    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirm = this.confirm.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.setState({openDialog: false});
  }

  renderDeliverySection() {
    const { handleBsbBlur, bankName } = this.props;
    return renderDeliveryFields(this.selectedFulfilmentType(), {}, this.props.promotion.country, ENABLE_ADDRESS_AUTO_COMPLETE, handleBsbBlur, bankName);
  }

  selectedFulfilmentType() {
    const { promotion, formData } = this.props;

    return _.find(promotion.fulfilment_types, function(t) { return t.id == formData.fulfilment_type_id });
  }

  handleCaptcha(captchaResponse) {
    this.setState({captchaResponse});
  }

  acceptTermsLabel() {
    const { promotion } = this.props;
    return (
      <React.Fragment>
        I have read and agree to the&nbsp;
        {
          (promotion.terms_and_conditions.url || promotion.terms_and_conditions.content) &&
          (promotion.terms_and_conditions.external ?
              <a href={promotion.terms_and_conditions.url} target="_blank">Terms and Conditions</a>
            :
              <NavLink exact to={{pathname: '/terms', search: this.props.location.search}} target="_blank">Terms and Conditions</NavLink>
          )
        }
        &nbsp;and&nbsp;
        {
          (promotion.privacy_policy.url || promotion.privacy_policy.content) &&
            (promotion.privacy_policy.external ?
              <a href={promotion.privacy_policy.url} target="_blank">Privacy Policy</a>
            :
              <NavLink exact to={{pathname: '/privacy_policy', search: this.props.location.search}} target="_blank">Privacy Policy</NavLink>
          )
        }
      </React.Fragment>
    )
  }

  handleSubmit(data, _resetForm, _invalidateForm){
    const { promotion } = this.props;
    const { captchaResponse, confirmed } = this.state;

    if (_.isEmpty(captchaResponse)) {
      this.setState({captchaError: 'Captcha is required'});
    } else {
      this.setState({captchaError: null});
      data.captcha_response = captchaResponse;

      if (promotion.confirmable && !confirmed) {
        this.setState({openDialog: true});
      } else {
        this.props.handleNext(data);
        this.setState({confirmed: false});
        // Reset recaptcha
        if (this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
          this.handleCaptcha(null);
        }
      }
    }
  }

  confirm() {
    this.setState({confirmed: true, openDialog: false}, () => this.formRef.current.submit());
  }

  showConfirmDialog() {
    const { promotion } = this.props;

    const type = this.selectedFulfilmentType().type;
    const data = this.formRef.current.getModel();

    return (
      <Dialog
        open={this.state.openDialog}
        onClose={this.closeDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm Details</DialogTitle>
        <DialogContent>
          <Typography paragraph>Please double check and confirm your details prior to submission</Typography>

          <Typography className="bold" variant="subheading">Name</Typography>
          <Typography paragraph>{data.name}</Typography>

          <Typography className="bold" variant="subheading">Email</Typography>
          <Typography paragraph>{data.email}</Typography>

          {type == 'bank_transfer' &&
            <React.Fragment>
              <Typography className="bold" variant="subheading">Account Name</Typography>
              <Typography paragraph>{data.account_name}</Typography>

              {promotion.country === 'Australia' &&
                <React.Fragment>
                  <Typography className="bold" variant="subheading">BSB</Typography>
                  <Typography paragraph>{data.bsb}</Typography>
                </React.Fragment>
              }

              <Typography className="bold" variant="subheading">Account Number</Typography>
              <Typography paragraph>{data.account_number}</Typography>
            </React.Fragment>
          }
          {(type === 'cheque' || type === 'physical_gift') &&
            <React.Fragment>
              <Typography className="bold" variant="subheading">Address</Typography>
              <Typography>{data.address_line_1}</Typography>
              <Typography>{data.address_line_2}</Typography>
              {promotion.country === 'Australia' ?
                <Typography paragraph>{data.suburb} {data.state} {data.postcode}</Typography>
              :
                <React.Fragment>
                  <Typography>{data.suburb}</Typography>
                  <Typography paragraph>{data.state} {data.postcode}</Typography>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog}>Cancel</Button>
          <Button color='primary' onClick={this.confirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { promotion, handleBack } = this.props;
    const { openDialog, captchaError } = this.state;

    return (
      <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
        <Grid container spacing={8} justify="center">
          {openDialog && this.showConfirmDialog()}
          <Grid item sm={8} xs={11}>
            <Typography className="inline" variant="subheading">
              {promotion.competition ? 'You are entering to win: ' : 'Please enter your details below to process and fulfil your '}
            </Typography>
            <Typography className="inline bold" variant="subheading" color="primary">{this.selectedFulfilmentType().name}</Typography>

            {this.renderDeliverySection()}

            <CheckboxInput name="acceptance" label={this.acceptTermsLabel()} value="0"
              validations="equals:&quot;1&quot;"
              validationErrors={{
                equals: 'Must accept to continue'
              }}
            />

            {promotion.consent_checks.map((check, index) =>
              <React.Fragment key={index}>
                <CheckboxInput name={`consent_checks_attributes[${index}][accepted]`} label={check.label} value="0"
                  validations={check.required ? "equals:1" : {}}
                  validationErrors={{
                    equals: 'Must accept to continue'
                  }}
                />
                <TextInput name={`consent_checks_attributes[${index}][text]`} type="hidden" label="Text" value={check.label} />
              </React.Fragment>
            )}

            <ReCAPTCHA
              sitekey={window.CAPTCHA_CLIENT_KEY || ''}
              onChange={this.handleCaptcha}
              className="g-recaptcha"
              ref={this.recaptchaRef}
            />
            {_.isEmpty(captchaError) ? '' :
              <FormHelperText error={true}>{captchaError}</FormHelperText>
            }
          </Grid>
        </Grid>
        <div className="button-container">
          <Button onClick={handleBack}>Back</Button>
          <Button type="submit" variant="raised" color="primary">Submit</Button>
        </div>
      </Formsy>
    )
  }
}

export default withRouter(DeliveryForm);
