import { addValidationRule } from 'formsy-react';
import moment from 'moment';

const format = 'DD/MM/YYYY'; // Used by us
const datePickerFormat = 'YYYY-MM-DD'; // Used by material-ui-pickers

// Override equals to use == instead of ===
addValidationRule('equals', function (_values, value, compareValue) {
  if (!compareValue) return true;
  return value == compareValue;
});

addValidationRule('dateSameOrAfter', function (_values, value, compareValue) {
  if (!compareValue) return true;
  return moment(value, format).isSameOrAfter(moment(compareValue, datePickerFormat));
});

addValidationRule('dateSameOrBefore', function (_values, value, compareValue) {
  if (!compareValue) return true;
  return moment(value, format).isSameOrBefore(moment(compareValue, datePickerFormat));
});

addValidationRule('numberEqualOrGreater', function (_values, value, compareValue) {
  if (!compareValue) return true;
  return value >= compareValue
});

addValidationRule('numberEqualOrLesser', function (_values, value, compareValue) {
  if (!compareValue) return true;
  return value <= compareValue
});

// Validate 10 digit number starting with 04, no spaces
addValidationRule('auMobileNumber', function (_values, value) {
  return /^(04)\d{8}$/.test(value);
});
