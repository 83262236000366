import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { CssBaseline, Grid, Typography, Button, CircularProgress, Tooltip, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonPin';
import { fetchPromotion } from 'actions/PromotionActions';
import renderHTML from 'react-render-html';
import LandingPage from './LandingPage';
import PromoContentPage from './PromoContentPage';
import PreparationCheckModal from 'components/PreparationCheckModal';

export class PromoPage extends React.Component {

  componentDidMount() {
    if (window.PROMO_ID) {
      this.props.fetchPromotion(window.PROMO_ID);
    }
  }

  renderLinks() {
    const { promotion } = this.props;

    return (
      <React.Fragment>
        {(promotion.terms_and_conditions.url || promotion.terms_and_conditions.content) &&
          (promotion.terms_and_conditions.external ?
            <a href={promotion.terms_and_conditions.url} target="_blank"><Button>Terms and Conditions</Button></a>
          :
            <NavLink exact to={{pathname: '/terms', search: this.props.location.search}} activeClassName="active"><Button>Terms and Conditions</Button></NavLink>
          )
        }
        {(promotion.privacy_policy.url || promotion.privacy_policy.content) &&
          (promotion.privacy_policy.external ?
            <a href={promotion.privacy_policy.url} target="_blank"><Button>Privacy Policy</Button></a>
          :
            <NavLink exact to={{pathname: '/privacy_policy', search: this.props.location.search}} activeClassName="active"><Button>Privacy Policy</Button></NavLink>
          )
        }
        {(promotion.faqs.url || promotion.faqs.content) &&
          (promotion.faqs.external ?
            <a href={promotion.faqs.url} target="_blank"><Button>FAQ</Button></a>
          :
            <NavLink exact to={{pathname: '/faq', search: this.props.location.search}} activeClassName="active"><Button>FAQ</Button></NavLink>
          )
        }
        {promotion.custom_tabs.map((custom_tab, index) =>
          <div key={index} className='custom-tab-nav'>
            {(custom_tab.content_external || custom_tab.content) &&
              (custom_tab.content_url ?
                <a href={custom_tab.content_url} target="_blank"><Button>{custom_tab.label}</Button></a>
              :
                <NavLink exact to={{pathname: "/" + custom_tab.path, search: this.props.location.search}} activeClassName="active"><Button>{custom_tab.label}</Button></NavLink>
              )
            }
          </div>
        ) }
      </React.Fragment>
    )
  }

  render() {
    const { isFetching, promotion, navigateTo, location } = this.props;

    if (!window.PROMO_ID) {
      return (
        <h1>Not found</h1>
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={16} justify="center">
          {!promotion || isFetching ?
            <Grid container spacing={16} justify="center">
              <CircularProgress />
            </Grid>
          :
            <Switch>
              <Route exact path='/' render={() => <LandingPage promotion={promotion} />} />
              <Route render={() => <PromoContentPage promotion={promotion} />} />
            </Switch>
          }
          {promotion &&
            <Grid item className="terms" xs={8}>
              <Typography variant="caption" gutterBottom>
                {renderHTML(promotion.disclaimer)}
              </Typography>
              {!promotion.external &&
                <div className="link-container">
                  {promotion && promotion.has_preparation_checks ?
                    <PreparationCheckModal promotion={promotion} onSubmit={() => navigateTo('/claim' + location.search)}
                      openComponent={(props) =>
                        <NavLink exact to={{pathname: '/claim'}} activeClassName="active" {...props}>
                          <Button onClick={(e) => e.preventDefault()}>{promotion.competition ? 'Enter' : 'Claim'}</Button>
                        </NavLink>
                      }
                    />
                  :
                    <NavLink exact to={{pathname: '/claim', search: location.search}} activeClassName="active">
                      <Button>{promotion.competition ? 'Enter' : 'Claim'}</Button>
                    </NavLink>
                  }
                  {promotion.trackable &&
                    <NavLink exact to={{pathname: '/track', search: location.search}} activeClassName="active">
                      <Button>Track</Button>
                    </NavLink>
                  }
                  {this.renderLinks()}
                  <NavLink exact to={{pathname: '/contact', search: location.search}} activeClassName="active">
                    <Button>Contact us</Button>
                  </NavLink>
                </div>
              }
              <Typography variant="caption" gutterBottom className="gutter-top">
                Powered by WAIVPAY
              </Typography>
            </Grid>
          }
        </Grid>
        {window.PROMOTION_OPEN_OVERRIDE &&
          <Tooltip title="You are viewing as an administrator">
            <Avatar className="admin-view">
              <PersonIcon />
            </Avatar>
          </Tooltip>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.promotion
  };
}

const mapDispatchToProps = {
  fetchPromotion: (id) => fetchPromotion(id),
  navigateTo: (path) => push(path)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoPage));
