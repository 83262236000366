import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import SubmissionForm from '../SubmissionForm';
import FlashMessage from '../FlashMessage';
import ThanksPage from './ThanksPage';
import TrackSubmissionPage from './TrackSubmissionPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import FAQPage from './FAQPage';
import ContactUsPage from './ContactUsPage';
import CustomTabPage from './CustomTabPage';
import UpdateClaimPage from './UpdateClaimPage';
import UpdateThanksPage from './UpdateThanksPage';

export class PromoContentPage extends React.Component {

  render() {
    const { promotion } = this.props;

    return (
      <React.Fragment>
        <div className="promo-banner background" title="Promo banner background" style={{'backgroundImage': 'url("' + promotion.banner_background + '")'}}></div>
        <Grid className="form-grid" item xs={10} md={5}>
          <Paper className="form-container" elevation={1}>
            <FlashMessage />
            <Switch>
              <Route path="/terms" render={() => <TermsAndConditionsPage content={promotion.terms_and_conditions.content} />} />
              <Route path="/privacy_policy" render={() => <PrivacyPolicyPage content={promotion.privacy_policy.content} />} />
              <Route path="/faq" render={() => <FAQPage content={promotion.faqs.content} />} />
              {promotion.trackable &&
                <Route path="/track" render={() => <TrackSubmissionPage promotion_id={promotion.id} />} />
              }
              {promotion.custom_tabs.map((custom_tab, index) =>
                <Route key={index} path={"/" + custom_tab.path} render={() => <CustomTabPage content={custom_tab.content} label={custom_tab.label} />} />
              )}
              <Route path="/contact" render={() => <ContactUsPage promotion={promotion} />} />
              <Route path="/thanks" render={() => <ThanksPage promotion={promotion} />} />
              <Route path="/update_claim" render={() => <UpdateClaimPage promotion={promotion} />} />
              <Route path="/update_success" render={() => <UpdateThanksPage promotion={promotion} />} />
              <Route path="/claim" render={() => <SubmissionForm promotion={promotion} />} />
            </Switch>
          </Paper>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withRouter(PromoContentPage);
