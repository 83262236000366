import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  FETCH_PROMOTION_REQUEST,
  FETCH_PROMOTION_SUCCESS,
  FETCH_PROMOTION_FAILURE
} from 'actions/PromotionActions';

const initialState = {
  isFetching: false,
  promotion: null
};

export default function promo(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (window.GA_TRACKING_ID) {
        ReactGA.pageview(window.location.href);
      }
      if (window.FB_PIXEL_ID) {
        ReactPixel.pageView();
      }
      return state;
    }

    case FETCH_PROMOTION_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        promotion: null
      });
    }
    case FETCH_PROMOTION_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        promotion: action.promotion
      });
    }
    case FETCH_PROMOTION_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
