import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import TextInput from './TextInput';
import { Typography } from '@material-ui/core';
import { renderAddressFields } from 'utils/deliveryRenderer';
import humanizeString from 'humanize-string';

export class AddressInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canonical_address: '',
      address_line_1: '',
      address_line_2: '',
      suburb: '',
      state: '',
      postcode: '',
      country: this.props.country,
      auto: true
    };

    this.assignAustralianAddress = this.assignAustralianAddress.bind(this);
    this.assignNewZealandAddress = this.assignNewZealandAddress.bind(this);
    this.assignAddress = this.props.country == 'Australia' ? this.assignAustralianAddress : this.assignNewZealandAddress;
  }

  componentDidMount() {
    this.widget = new AddressFinder.Widget(document.getElementById(this.props.name), ADDRESS_FINDER_KEY,
                                           this.props.country == 'Australia' ? 'AU' : 'NZ', {empty_content: 'No addresses were found', byline: false});
    this.widget.on('result:select', this.assignAddress);
  }

  assignNewZealandAddress(canonical_address, address) {
    this.setState({
      canonical_address: canonical_address,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      suburb: address.city,
      postcode: address.postcode,
      state: address.region
    });
    this.props.setValue(canonical_address);
  }

  assignAustralianAddress(canonical_address, address) {
    this.setState({
      canonical_address: canonical_address,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      suburb: humanizeString(address.locality_name),
      postcode: address.postcode,
      state: address.state_territory
    });
    this.props.setValue(canonical_address);
  }

  render() {
    const { acceptPoBox, required, validationErrors } = this.props;
    const label = this.props.label || "Address";

    return (
      <React.Fragment>
        {this.state.auto &&
          <TextInput label={label} name={this.props.name} value={this.state.canonical_address} required={required} validationErrors={validationErrors} hint={acceptPoBox ? '' : 'Please note that we cannot accept PO Boxes and lockers'} />
        }
        <div className={this.state.auto ? 'hidden' : ''}>
          {renderAddressFields(this.props.country, acceptPoBox, this.state)}
        </div>
        <Typography paragraph>
          <a href="javascript:void(0)" onClick={() => this.setState({auto: !this.state.auto})}>
            {this.state.auto ? "Can't find your address? Click here to enter address manually" : "Back to address search"}
          </a>
        </Typography>
      </React.Fragment>
    );
  }
}

AddressInput.propTypes = {
  ...propTypes
};

export default withFormsy(AddressInput);
