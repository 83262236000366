import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Formsy from 'formsy-react';
import CheckboxInput from 'components/inputs/CheckboxInput';
import Linkify from 'react-linkify';
import renderHTML from 'react-render-html';

export class PreparationCheckModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({open: false});
  }

  handleSubmit() {
    this.handleClose();
    this.props.onSubmit();
  }

  render() {
    const { promotion, children, onSubmit, openComponent } = this.props;
    const OpenComponent = openComponent;
    const renderOpenComponent = <OpenComponent onClick={() => this.setState({open: true})} />;

    return (
      promotion && promotion.has_preparation_checks ?
        <React.Fragment>
          {renderOpenComponent}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className="prep-check-modal"
          >
            {!promotion.preparation_check_content &&
              <DialogTitle id='alert-dialog-title'>In order to complete your claim please ensure that you:</DialogTitle>
            }
            <DialogContent>
              {promotion.preparation_check_content &&
                <Typography variant="body1">
                  {renderHTML(promotion.preparation_check_content)}
                </Typography>
              }
              <Formsy onValidSubmit={this.handleSubmit}>
                {promotion.preparation_checks.map((confirmation, index) =>
                  <CheckboxInput key={index} name={'preparation_check_' + confirmation.id} value='0'
                    label={<Linkify properties={{target: '_blank'}}>{confirmation.description}</Linkify>}
                    validations="equals:&quot;1&quot;"
                    validationErrors={{
                      equals: 'Must accept to continue'
                    }}
                  />
                )}
                <div className='button-container' style={{marginTop: '2em', textAlign: 'center'}}>
                  <Button onClick={this.handleClose}>Cancel</Button>
                  <Button type='submit' variant='raised' color='primary'>Confirm</Button>
                </div>
              </Formsy>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      :
        null
    )
  }
}

export default PreparationCheckModal;
