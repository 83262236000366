import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import Slider from '@material-ui/lab/Slider';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';

export class SlideInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value || this.props.value == 0) {
      this.props.setValue(this.props.value);
    }

    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event, value) {
    this.props.setValue(value);
  }

  getInputValue(nullReturn) {
    const { getValue } = this.props;

    // To handle number inputs having 0 instead of blank
    if (getValue() || getValue() === 0) {
      return getValue();
    } else {
      return nullReturn;
    }
  }

  render() {
    const { name, label, required, min, max, hint, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container" style={{touchAction: 'none'}}>
        <InputLabel htmlFor={name} error={isError} required={required}>{label}</InputLabel>
        <Typography align="center">{this.getInputValue('Click or drag to select')}</Typography>
        <Slider
          id={name}
          name={name}
          min={min}
          max={max}
          step={1}
          onChange={this.changeValue}
          value={this.getInputValue((min + max) / 2)}
          style={{padding: '1em 0'}}
        />
        <Typography className="inline" align="left">{min}</Typography>
        <Typography className="inline" style={{float: 'right'}}>{max}</Typography>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

SlideInput.propTypes = {
  ...propTypes
};

export default withFormsy(SlideInput);
