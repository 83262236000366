import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Button } from '@material-ui/core';
import SelectInput from 'components/inputs/SelectInput';
import renderInput from 'utils/inputRenderer';

export default class DetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantID: null
    }

    this.updateMerchantID = this.updateMerchantID.bind(this);
  }

  componentDidMount() {
    const { promotion, updateProductId } = this.props;
    if (promotion.products && promotion.products.length === 1) {
      updateProductId(promotion.products[0].value);
    }
  }

  renderProductSelect() {
    const { promotion, formData } = this.props;

    if (promotion.products && promotion.products.length > 1) {
      return (
        <SelectInput name="product_id" label="Product" value={formData.product_id} placeholder="Select Product" required
          options={promotion.products}
          validationErrors={{isDefaultRequiredValue: "can't be blank"}}
        />
      )
    }
    return null
  }

  updateMerchantID(id) {
    this.setState({merchantID: id});
  }

  render() {
    const { promotion, handleNext, formData, filenames, onFileChange } = this.props;

    const options = { onFileChange, updateMerchantID: this.updateMerchantID, merchantID: this.state.merchantID };

    return (
      <Formsy onValidSubmit={handleNext}>
        <Grid container spacing={8} justify="center">
          <Grid item sm={8} xs={11}>
            {this.renderProductSelect()}
            {promotion.form_schema.map((field, i) => (
              renderInput(field, formData.fields[field.id], i, filenames, options)
            ))}
          </Grid>
        </Grid>
        <div className="button-container">
          <Button type="submit" variant="raised" color="primary">Next</Button>
        </div>
      </Formsy>
    )
  }
}
