import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { InputLabel, Typography, TextField, Paper, MenuItem, FormHelperText } from '@material-ui/core';
import DropdownArrow from '@material-ui/icons/ArrowDropDown';
import MediaQuery from 'react-responsive';
import _ from 'lodash';

const styles = theme => ({
  control: {
    cursor: 'pointer'
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
});

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      className={props.selectProps.classes.control}
      error={props.selectProps.error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color={props.selectProps.error ? 'error' : 'textPrimary'}
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator: () => <DropdownArrow style={{color: '#0000008a'}} />,
  IndicatorSeparator: () => null
};

export class AutocompleteInput extends React.Component {
  constructor(props) {
    super(props);

    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(option) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(option);
    setValue(option.value);
  }

  render() {
    const { name, label, required, options, disabled, hint, getValue, getErrorMessage, isPristine, classes } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <InputLabel htmlFor={name} error={isError} required={required}>{label}</InputLabel>
        <Select
          error={isError}
          inputId={name}
          name={name}
          options={[{label: '', value: '', isDisabled: true}].concat(options)}
          components={components}
          classes={classes}
          placeholder={
            <MediaQuery maxWidth={767}>
              {(matches) => {
                if (matches) {
                  return 'Select/search';
                } else {
                  return 'Select or type to search';
                }
              }}
            </MediaQuery>
          }
          onChange={this.changeValue}
          value={_.filter(options, {value: getValue()})}
          disabled={disabled}
          fullWidth
        />
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

AutocompleteInput.propTypes = {
  ...propTypes
};

export default withStyles(styles)(withFormsy(AutocompleteInput));
