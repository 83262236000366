import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    maxWidth: 350
  },
  media: {
    height: 140
  }
};

var classNames = require('classnames');

export class FulfilmentTypeOption extends React.Component {
  render() {
    const { option, classes, handleNext, optionSelected } = this.props;
    var isActive = optionSelected ? 'active' : '';

    return (
      <React.Fragment>
        <Grid container spacing={24} justify="center">
          <Grid item xs={'auto'}>
            <Card className={classNames(classes.card, 'fulfilment-type-card', isActive)}>
               {option.gift_image &&
                 <CardMedia
                   className={classNames(classes.media, 'gift-image')}
                   image={option.gift_image}
                 />
               }
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  {option.name}
                </Typography>
                <Typography component="p">
                  {option.description}
                </Typography>
              </CardContent>
              <CardActions style={{justifyContent: 'center'}}>
                <Button variant="contained" color="primary" value={option.id} onClick={() => handleNext(option.id)}>Select</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

FulfilmentTypeOption.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FulfilmentTypeOption);
