import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';
import { bugsnagClient } from 'utils/bugsnag';

export const FETCH_SUBMISSION_REQUEST = 'FETCH_SUBMISSION_REQUEST';
export const FETCH_SUBMISSION_SUCCESS = 'FETCH_SUBMISSION_SUCCESS';
export const FETCH_SUBMISSION_FAILURE = 'FETCH_SUBMISSION_FAILURE';

export const SUBMIT_SUBMISSION_REQUEST = 'SUBMIT_SUBMISSION_REQUEST';
export const SUBMIT_SUBMISSION_SUCCESS = 'SUBMIT_SUBMISSION_SUCCESS';
export const SUBMIT_SUBMISSION_FAILURE = 'SUBMIT_SUBMISSION_FAILURE';

export const FETCH_FAILED_SUBMISSION_REQUEST = 'FETCH_FAILED_SUBMISSION_REQUEST';
export const FETCH_FAILED_SUBMISSION_SUCCESS = 'FETCH_FAILED_SUBMISSION_SUCCESS';
export const FETCH_FAILED_SUBMISSION_FAILURE = 'FETCH_FAILED_SUBMISSION_FAILURE';

export const UPDATE_FAILED_SUBMISSION_REQUEST = 'UPDATE_FAILED_SUBMISSION_REQUEST';
export const UPDATE_FAILED_SUBMISSION_SUCCESS = 'UPDATE_FAILED_SUBMISSION_SUCCESS';
export const UPDATE_FAILED_SUBMISSION_FAILURE = 'UPDATE_FAILED_SUBMISSION_FAILURE';

export const FETCH_BANK_NAME_SUCCESS = 'FETCH_BANK_NAME_SUCCESS';

function fetchSubmissionRequest() {
  return {
    type: FETCH_SUBMISSION_REQUEST
  }
}

function fetchSubmissionSuccess(submission) {
  return {
    type: FETCH_SUBMISSION_SUCCESS,
    submission
  }
}

function fetchSubmissionFailure(error) {
  return {
    type: FETCH_SUBMISSION_FAILURE,
    error: error
  }
}

function submitSubmissionRequest() {
  return {
    type: SUBMIT_SUBMISSION_REQUEST
  }
}

function submitSubmissionSuccess(submission) {
  return {
    type: SUBMIT_SUBMISSION_SUCCESS,
    submission
  }
}

function submitSubmissionFailure() {
  return {
    type: SUBMIT_SUBMISSION_FAILURE
  }
}

function fetchFailedSubmissionRequest() {
  return {
    type: FETCH_FAILED_SUBMISSION_REQUEST
  }
}

function fetchFailedSubmissionSuccess(submission) {
  return {
    type: FETCH_FAILED_SUBMISSION_SUCCESS,
    submission
  }
}

function fetchFailedSubmissionFailure(error) {
  return {
    type: FETCH_FAILED_SUBMISSION_FAILURE
  }
}

function fetchBankNameSuccess(bank_name) {
  return {
    type: FETCH_BANK_NAME_SUCCESS,
    bank_name
  }
}

function updateFailedSubmissionRequest() {
  return {
    type: UPDATE_FAILED_SUBMISSION_REQUEST
  }
}

function updateFailedSubmissionSuccess(submission) {
  return {
    type: UPDATE_FAILED_SUBMISSION_SUCCESS
  }
}

function updateFailedSubmissionFailure() {
  return {
    type: UPDATE_FAILED_SUBMISSION_FAILURE
  }
}

export function fetchSubmission(promotionId, trackingId, captchaResponse) {
  return dispatch => {
    dispatch(fetchSubmissionRequest());
    return axios.get('/promotions/' + promotionId + '/submissions/' + trackingId, {params: {captcha_response: captchaResponse}})
      .then(response => {
        dispatch(fetchSubmissionSuccess(response.data.submission));
      }).catch(error => {
        dispatch(fetchSubmissionFailure(error.response.data.error));
      });
  }
}

export function submitSubmission(promotionId, data) {
  return dispatch => {
    dispatch(submitSubmissionRequest());
    return axios.post('/promotions/' + promotionId + '/submissions', {submission: data})
      .then(response => {
        dispatch(submitSubmissionSuccess(response.data.submission));
        dispatch(push('/thanks'));
      }).catch(error => {
        dispatch(submitSubmissionFailure());
        if (error.response.data.error) {
          dispatch(flashMessage(error.response.data.error, {isError: true}));
        } else {
          dispatch(flashMessage('Unfortunately, your submission was unsuccessful', {isError: true}));

          // Notify bugsnag of errors
          bugsnagClient.notify(new Error('Submission submit error'), {
            metaData: {
              promotion_id: promotionId,
              data: {submission: data},
              response: error.response,
              errors: error.response.data.error
            }
          });
        }
      });
  }
}

export function fetchFailedSubmission(promotionId, token) {
  return dispatch => {
    dispatch(fetchFailedSubmissionRequest());
    return axios.get('/promotions/' + promotionId + '/failed_submissions', {params: {token}})
      .then(response => {
        dispatch(fetchFailedSubmissionSuccess(response.data.submission));
      }).catch(error => {
        dispatch(fetchFailedSubmissionFailure());
        dispatch(flashMessage('Claim not found', {isError: true}));
      });
  }
}

export function updateFailedSubmission(promotionId, token, data) {
  return dispatch => {
    dispatch(updateFailedSubmissionRequest());
    return axios.put('/promotions/' + promotionId + '/failed_submissions', {submission: data, token})
      .then(response => {
        dispatch(updateFailedSubmissionSuccess());
        dispatch(push('/update_success'));
        dispatch(flashMessage('Your claim details were updated successfully'));
      }).catch(error => {
        dispatch(updateFailedSubmissionFailure());
        dispatch(flashMessage(error.response.data.error, {isError: true}));
      });
  }
}

export function fetchBankName(bsb) {
  return dispatch => {
    return axios.post('/bsb', {bsb})
      .then(response => {
        dispatch(fetchBankNameSuccess(response.data.bank_name));
      });
  }
}
